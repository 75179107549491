import './footer.css';
import Logo from '../../assets/logo.png';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LanguageIcon from '@mui/icons-material/Language';

const Footer = () => {
    return (
        <>
            <div className='row border border-width-5'>
                <div className='d-flex'>
                    <div className='col-2 pt-4'>
                        <img src={Logo} alt="house" />
                    </div>
                    <div className='col-10 p-2 footer-bg'>
                        <div>
                            <span className='fs-4 fw-bold'> <PhoneIphoneIcon className='blue-icon-text fs-4'></PhoneIphoneIcon> +91 95854 02224 / 95855 02224</span></div>
                        <div>
                            <span className='fs-6 '> <PlaceIcon className='blue-icon-text fs-4'></PlaceIcon> 40, Vivekanandhar Street, Nethajipuram, Coimbatore-641033</span>
                        </div>
                        <div>
                            <span className='fs-6'> <LocalPostOfficeIcon className='blue-icon-text fs-4'></LocalPostOfficeIcon> arkbuilders.cbee@gmail.com</span>
                        </div>
                        <div>
                            <span className='fs-6'> <LanguageIcon className='blue-icon-text fs-4'></LanguageIcon> www.arkbuilderscbe.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;