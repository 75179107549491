import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import Dialog from '@mui/material/Dialog';

export interface ContactUsProps {
    open: boolean;
    onCloseAction: () => void;
}

const ContactUs = (props: ContactUsProps) => {

    const handleClose = (value: string) => {
        props.onCloseAction();
    };

    return (
        <Dialog onClose={handleClose} open={props.open}>
            <div className="p-3">
                <p className="fs-3">For all your construction needs</p>
                <p className="fs-4">Feel free to reach us through below contacts at anytime !</p>
                <div>
                    <span className='fs-4 fw-bold'> <PhoneIphoneIcon className='blue-icon-text fs-4'></PhoneIphoneIcon> +91 95854 02224 / 95855 02224</span>
                </div>
                <div className="py-2">
                    <span className='fs-6'> <LocalPostOfficeIcon className='blue-icon-text fs-4'></LocalPostOfficeIcon> arkbuilders.cbee@gmail.com</span>
                </div>
            </div>

        </Dialog>
    );
}

export default ContactUs;