import { useState } from 'react';
import './banner.css';
import BannerImage from '../../assets/background-layout-1.jpg'
import ContactUs from '../../components/contact-us/contact-us';

const Banner = () => {

    const [openContactUs, setOpenContactUs] = useState<boolean>(false);

    const closeContactUs = () => {
        setOpenContactUs(false);
    }

    return (
        <>
            <div className='banner-alignment'>
                <div className='row'>
                    <div className='col-4 left-pane-content text-start'>
                        <div className='fs-1'>Bring Your Dreams into Reality, With Us</div>
                        <div className='d-flex py-3'>
                            <button type="button" className="btn btn-dark" onClick={() => { setOpenContactUs(!openContactUs) }}>Contact Us</button>
                            <button type="button" className="btn btn-light mx-2">Call Back</button>
                        </div>
                    </div>

                    <div className='col-8 aligh-text-center align-image'>
                        <img src={BannerImage} className='banner-image' alt="house" />
                    </div>

                </div>
            </div >

            <ContactUs open={openContactUs} onCloseAction={closeContactUs}></ContactUs>

        </>
    )
}

export default Banner;

