import PriceDetails from '../data/price-details.json';
import DetailsDescription from '../data/details-description.json';
import './price-plans.component.css';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const PricePlans = () => {

    const contentToPrint = useRef(null);
    const handlePrint: any = useReactToPrint({
        documentTitle: "ark-builders-pricing-details",
        removeAfterPrint: true,
    });

    const headers = PriceDetails.map((priceDet: any) => {
        return <th className='header-cell'>{priceDet.package.toUpperCase()}</th>
    });

    const body = DetailsDescription.map((description: string, index: number) => {
        return (
            <tr>
                {
                    PriceDetails.map((detail: any, index: number) => {
                        return (
                            <td className={index === 0 ? 'description-cell' : ''}>
                                {typeof detail[description] === 'string' ?
                                    detail[description]
                                    :
                                    <ul>
                                        {
                                            detail[description].map((descriptionItems: string) => {
                                                return <li>{descriptionItems}</li>
                                            })
                                        }
                                    </ul>
                                }

                            </td>
                        )
                    })
                }
            </tr>
        )
    })

    return (
        <>
            <hr />
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button onClick={() => { handlePrint(null, () => contentToPrint.current); }}
                    className="btn btn-primary me-md-2" type="button">Print</button>
            </div>

            <div ref={contentToPrint} className="table-responsive">
                <table id="pricing-table" className="table table-bordered caption-top">
                    <caption className='px-2'>ARK Builders - Pricing Details</caption>
                    <thead>
                        <tr>
                            {headers}
                        </tr>
                    </thead>
                    <tbody>
                        {body}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PricePlans;

