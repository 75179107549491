import React from 'react';
import './header.css';
import Logo from '../../assets/logo.png';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    console.log(location.pathname);
    return (
        <>
            <div style={{ display: 'block' }}>

                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className='float-start'>
                            <a className="navbar-brand" href="#"><img src={Logo} alt="house" /></a>
                        </div>
                        <div className='float-end'>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className={"nav-link " + (location.pathname === "/" ? " active " : "")} href="/">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={"nav-link " + (location.pathname === "/price" ? " active " : "")} href="/price">Pricing</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Sign Up</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header;