import React from 'react';
import './whatWeDo.css';
import FloorPlan from '../../assets/floorplan.jpg';
import Interior from '../../assets/interior-image.jpg';
import CastleTwoToneIcon from '@mui/icons-material/CastleTwoTone';
import YardTwoToneIcon from '@mui/icons-material/YardTwoTone';

const WhatWeDo = () => {
    return (
        <>
            <div className='whatwedo-alignment'>
                <div className='row'>
                    <div className='col justify-content-center d-flex'>
                        <h2>Quality You Can Build On</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col justify-content-center d-flex'>
                        <p className=''>Everything you dream to make needs Professionals like us.</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 aligh-text-center img-gradient'>
                        <img src={FloorPlan} className='align-image' alt="project-1" />
                    </div>
                    <div className='col-6 align-description'>
                        <div>
                            <CastleTwoToneIcon className='font-large-icon'></CastleTwoToneIcon>
                            <h3>
                                3D Designs
                            </h3>
                            <p className='justify-content'>
                                3D Modelling lets architects plan beyond the traditional method of hand-drawn building plans. As technology has evolved, we’re now able to conjure up a three-dimensional visual of a building before it is fully built.
                            </p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6 align-description'>
                            <div>
                                <YardTwoToneIcon className='font-large-icon'></YardTwoToneIcon>
                                <h3>
                                    Interiors
                                </h3>
                                <p className='justify-content'>
                                    Interior design is the art and science of enhancing the interior of a building to achieve a healthier and more aesthetically pleasing environment for the people using the space.
                                    With a keen eye for detail and a creative flair, we plan, research and manages enhancement on our every projects.
                                </p>
                            </div>
                        </div>

                        <div className='col-6 aligh-text-center img-gradient'>
                            <img src={Interior} className='align-image' alt="project-1" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatWeDo;