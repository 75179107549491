import React from 'react';
import './projects.css';
import Project1 from '../../assets/designer.jpg'

const Projects = () => {
    return (
        <>
            <div className='projects-alignment'>
                <div className='row'>
                    <div className='col justify-content-center d-flex'>
                        <h4 className='projects-title'>OUR PROJECTS</h4>
                    </div>
                </div>
                <div>
                    <div className='project-logos'>
                        <div>
                            <img src={Project1} className='project-image' alt="project-1" />
                        </div>
                        <div>
                            <img src={Project1} className='project-image' alt="project-1" />
                        </div>
                        <div>
                            <img src={Project1} className='project-image' alt="project-1" />
                        </div>
                        <div>
                            <img src={Project1} className='project-image' alt="project-1" />
                        </div>
                        <div>
                            <img src={Project1} className='project-image' alt="project-1" />
                        </div>
                        <div>
                            <img src={Project1} className='project-image' alt="project-1" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects;