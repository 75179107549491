import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PricePlans from './price-plans/price-plans.component';
import Header from './home/header/header';
import Footer from './home/footer/footer';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBX7GSWuyVCEW_u9QmFhPp0PA4lWN2RfV8",
  authDomain: "arkbuilderscbe.firebaseapp.com",
  projectId: "arkbuilderscbe",
  storageBucket: "arkbuilderscbe.appspot.com",
  messagingSenderId: "260402055824",
  appId: "1:260402055824:web:d6905e9da3624654ec6615",
  measurementId: "G-J2CV8EHP1P"
};

const cong = initializeApp(firebaseConfig);

export default cong;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/price" element={<PricePlans />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
