import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './home/header/header';
import Banner from './home/banner/banner';
import Projects from './home/projects/projects';
import WhatWeDo from './home/whatWeDo/whatWeDo';
import Footer from './home/footer/footer';
import PricePlans from './price-plans/price-plans.component';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from '@mui/icons-material';

const App = () => {
  return (
    <>
      <div className="App container-fluid">
        <Banner />
        <Projects />
        <WhatWeDo />
      </div>

    </>
  );
}

export default App;
